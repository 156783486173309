import * as React from 'react';
import { Helmet } from 'react-helmet';

import { IHelmetLink, IHelmetMeta, IHelmetScript } from '../../types/helmet';

export interface IHelmetProps {
  title: string;
  metaTags?: IHelmetMeta[];
  links?: IHelmetLink[];
  scripts?: IHelmetScript[];
}

export const PageHelmet = ({ title, metaTags = [], links = [], scripts = [] }: IHelmetProps) => (
  <Helmet>
    <title>{title}</title>

    {metaTags.map(({ name, content }) => (
      <meta key={name} name={name} content={content} />
    ))}

    {links.map(attributes => (
      <link key={attributes.href} {...attributes} />
    ))}
    {scripts.map(({ content, attrs }, i) => {
      return React.createElement(
        'script',
        {
          key: `s${i}`,
          ...attrs,
        },
        content,
      );
    })}
  </Helmet>
);
