import * as React from 'react';
import { useSelector } from 'react-redux';

import { IApplicationState } from '../../types/redux';
import { PageHelmet } from '../../components/PageHelmet';

const HelmetContainer: React.FC<{}> = () => {
  const title = useSelector(({ seoData: { title } }: IApplicationState) => title);
  const helmetMetaTags = useSelector(({ seoData: { metaTags } }: IApplicationState) => metaTags);
  const helmetLinks = useSelector(({ seoData: { links } }: IApplicationState) => links);
  const scripts = useSelector(({ seoData: { scripts } }: IApplicationState) => scripts);

  const links = React.useMemo(() => {
    const result = [];

    if (helmetLinks.canonical) {
      result.push({ rel: 'canonical', href: helmetLinks.canonical });
    }

    return result;
  }, [helmetLinks]);

  const metaTags = React.useMemo(() => {
    const result = [];

    if (helmetMetaTags.description) {
      result.push({ name: 'description', content: helmetMetaTags.description });
    }

    if (helmetMetaTags.keywords) {
      result.push({ name: 'keywords', content: helmetMetaTags.keywords });
    }

    if (helmetMetaTags.noindex) {
      result.push({ name: 'robots', content: 'noindex' });
    }

    return result;
  }, [helmetMetaTags]);

  return <PageHelmet title={title} metaTags={metaTags} links={links} scripts={scripts} />;
};

// eslint-disable-next-line import/no-default-export
export default HelmetContainer;
